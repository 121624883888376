import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  pages: {
    textAlign: 'center',
    padding: '2rem 2rem 3rem',
  },
  titleStyle: {
    color: theme.colors.primary,
    textTransform: 'uppercase',
    fontSize: '2rem',
    lineHeight: '2rem',
    fontWeight: 600,

  },
  contianerContent: {
    maxWidth: 1170,
    margin: [[0, 'auto']],
    textAlign: 'left',
    paddingTop: 8,
    fontSize: 16,
    color: theme.colors.primary,
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
    },
    '& img': {
      margin: [[30, 0]],
    },
    '& h1': {
      lineHeight: 1.18,
    },
    '& h2': {
      lineHeight: 1.18,
      fontSize: '3.4rem',
    },
    '& h3': {
      lineHeight: 1.18,
      fontSize: '2.8rem',
    },
    '& h4': {
      lineHeight: 1.18,
      fontSize: '2.4rem',
    },
    '& h5': {
      lineHeight: 1.18,
      fontSize: '2rem',
    },
    '& h6': {
      lineHeight: 1.18,
      fontSize: '1.6rem',
    },
    '& iframe, & p iframe': {
      height: 220,
    },
    '& ol': {
      listStyleType: 'decimal',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& ul': {
      listStyleType: 'disc',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    pages: {
      padding: '4rem 0 3rem',
    },
    titleStyle: {
      fontSize: '2.8rem',
      lineHeight: '3.2rem',
    },
    contianerContent: {
      paddingTop: 30,
      '& iframe, & p iframe': {
        height: 430,
      },
    },
  },
}))

export default useStyles
